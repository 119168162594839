import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import Container from "../components/Container";
import PageContent from "../components/PageContent";
import { data } from "../zeyVenturesData";

const DMCA = () => (
  <Layout>
    <SEO title="DMCA Policy" description="Our DMCA policy and how to reach out to us." />
    <Container>
      <PageContent>
        <h1>Digital Millennium Copyright Act Notice Takedown Policy and Procedures</h1>
        <p>
          {data.businessName} respects the intellectual property rights of others just as it expects third parties to respect its
          rights. Pursuant to Digital Millennium Copyright Act, Title 17, United States Code, Section 512(c), a copyright owner or
          their agent may submit a takedown notice to us via our DMCA Agent listed below. As an internet service provider, we are
          entitled to claim immunity from said infringement claims pursuant to the “safe harbor” provisions of the DMCA.
        </p>
        <p>Send all takedown notices to the following.</p>
        <p>{data.address}</p>
        <p>{data.phone}</p>
        <p>Please send by email for prompt attention. {data.email}</p>
        <h2>Counter Notification – Restoration of Material</h2>
        <p>
          If you have received a notice of material being takedown because of a copyright infringement claim, you may provide us
          with a counter notification in an effort to have the material in question restored to the site. Said notification also
          must be given in writing to our DMCA Agent.
        </p>
        <p>Mail your counter notice to our DMCA Agent:</p>
        <p>{data.address}</p>
        <p>{data.phone}</p>
        <p>Please send by email for prompt attention. {data.email}</p>
        <h2>Repeat Infringer Policy</h2>
        <p>
          {data.businessName} takes copyright infringement very seriously. Pursuant to the repeat infringer policy requirements of
          the Digital Millennium Copyright Act, {data.businessName} maintains a list of DMCA notices from its copyright holders
          with its registered DMCA Agent.
        </p>
        <h2>Modifications</h2>
        <p>
          {data.businessName} reserves the right to modify the contents of this page and its policy for handling DMCA claims at
          any time for any reason. You are encouraged to check back to review this policy frequently for any changes.
        </p>
      </PageContent>
    </Container>
  </Layout>
);

export default DMCA;
